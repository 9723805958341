//$(document).foundation();

window.sr = ScrollReveal({ reset: true });

sr.reveal('.head', { duration: 2000, delay: 200, scale: 1, opacity: 0, origin: 'bottom', distance: '20vh' });

sr.reveal('.the-txt', { duration: 600, delay: 0, scale: 1, opacity: 0, origin: 'bottom', viewFactor: .35 });

sr.reveal('#vinyl', { duration: 2000, distance: '120vw', delay: 0, origin: 'top', viewFactor: 1.1, reset: false, 
	afterReveal: function(){
		$('#vinyl').css('position', 'fixed');
	}
});

sr.reveal('.tagline', { duration: 1200, delay: 0, scale: 1, opacity: 0, distance: '120vw', origin: 'top', viewFactor: 2 });

sr.reveal('.social', { duration: 600, delay: 0, scale: 1, opacity: 0, origin: 'bottom', viewFactor: 1 });

sr.reveal('.social .icon', { duration: 400, scale: .4, delay: 200, viewFactor: 4 }, 400);

